import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 } from "uuid"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  Section,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryFormBecomeAnDealer from "../components/PrimaryFormBecomeAnDealer"
import ListArrow from "../images/BulletIcon.svg"

const BannerBgImage = styled.div`
  margin: 0;
`
const SectionWhite = styled.div`
  background-color: #fff;
  position: relative;
  padding: 30px 0;
  ${BreakpointUp.sm`	
      padding:30px 20px;
      margin-top:-30px;
    `}
  ${BreakpointUp.md`	
      padding:30px 20px;
      margin-top:-50px;
    `}
  ${BreakpointUp.lg`	
      margin-top:-100px;
      padding:30px;
    `} 
    ${BreakpointUp.xl`	
      padding:50px;
      margin-top:-100px;
    `}
`

const BackgroundImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f4f9', endColorstr='#00ffffff',GradientType=1 );
    ${BreakpointUp.md`
          width: 30%;
        `}
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionDealer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

const SectionDealerContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.md`
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & h2 {
    font-size: 28px;
    line-height: 38px;
    @media (min-width: 768px) {
      font-size: 38px;
      line-height: 48px;
    }
    @media (min-width: 992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
  }
  & strong {
    display: block;
    color: #999;
    font-weight: 700;
    margin-bottom: 15px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
  }
  & ul {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    font-weight: 700;
    color: #000;
    font-size: 18px;
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 38px;
    }
    & li {
      position: relative;
      padding-left: 45px;
      vertical-align: text-top;
      margin: 0;
      line-height: 34px;
      + li {
        margin-top: 20px;
      }
      &:before {
        content: url(${ListArrow});
        display: inline-flex;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #fff;
        border: 6px solid rgba(252, 0, 2, 0.1);
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
`

const BecomeDealer = ({ data, location }) => {
  const pageData = data.contentfulBecomeADealerPage
  return (
    <Layout location={location}>
      <Seo title="Become a Dealer" description="Become a Dealer" />
      <BreadCrumb className="white">
        <div className="container">
          <Link to="/">Home / </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <Section pt="0" pb="0" xpt="30px" xpb="60px" bgColor="#fff">
        <BannerBgImage>
          <GatsbyImage
            image={pageData.heroImage.gatsbyImageData}
            alt={pageData.heroImage.title}
          />
        </BannerBgImage>
        <div className="container">
          <SectionWhite>
            <MainPanel>
              <LeftPannel>
                <MarkdownContent>
                  <h1>{pageData.heroTitle}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroContent.childMarkdownRemark.html,
                    }}
                  />
                </MarkdownContent>
              </LeftPannel>
              <RightPannel>
                <div className="isSticky">
                  <PrimaryFormBecomeAnDealer
                    states={data.allContentfulState.edges}
                    location={location}
                  />
                </div>
              </RightPannel>
            </MainPanel>
          </SectionWhite>
        </div>
      </Section>
      <Section pt="140px" pb="140px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <BackgroundImage>
          <GatsbyImage
            image={pageData.becomeSectionImage.gatsbyImageData}
            alt={pageData.becomeSectionImage.title}
          />
        </BackgroundImage>
        <div className="container">
          <SectionDealer>
            <SectionDealerContent>
              <h2>{pageData.becomeSectionTitle}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.becomeSectionDescription.childMarkdownRemark.html,
                }}
              />
              <strong>
                The representative will be checking for a few things:
              </strong>
              <ul>
                {pageData.becomeFeatures.map(item => (
                  <li key={v4()}>{item.content}</li>
                ))}
              </ul>
            </SectionDealerContent>
          </SectionDealer>
        </div>
      </Section>
      <CallNow />
    </Layout>
  )
}

export default BecomeDealer

export const pageQuery = graphql`
  query BecomeDealerQuery {
    contentfulBecomeADealerPage {
      pageName
      metaTitle
      metaDescription
      heroTitle
      heroContent {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        title
        gatsbyImageData
      }
      becomeSectionTitle
      becomeSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      becomeFeatures {
        content
      }
      becomeSectionImage {
        title
        gatsbyImageData
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
